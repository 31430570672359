import type { LinksFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { css } from "styled-system/css";
import { SENTRY_ENV } from "~/.server/env";
import { Alert } from "~/components/notice";
import styles from "./index.css?url";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: styles }];

export async function loader() {
  return {
    ENV: {
      SENTRY_ENV,
    },
  };
}

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  const contents = isRouteErrorResponse(error) ? (
    <>
      <h1>
        {error.status} {error.statusText}
      </h1>
      <p>{error.data}</p>
    </>
  ) : (
    <h1>Something went wrong</h1>
  );

  return (
    <div
      className={css({
        p: 4,
      })}
    >
      <Alert>{contents}</Alert>
    </div>
  );
};

export default function App() {
  const { ENV } = useLoaderData<typeof loader>();

  return (
    <>
      <Outlet />
      <script
        // biome-ignore lint/security/noDangerouslySetInnerHtml: FIXME globalThisに値を設定する良い案が他にない
        dangerouslySetInnerHTML={{
          __html: `globalThis.ENV = ${JSON.stringify(ENV)}`,
        }}
      />
    </>
  );
}
